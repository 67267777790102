<template>
    <div>
        <sn-table-group
            :tableGroupAttributes="tableGroupAttributes"
            @handleChange="handleChange"
            :commonMethods="commonMethods"
        ></sn-table-group>
    </div>
</template>
<script>
import { commonData } from "../../sn-table-group-common";
import { selfData } from "./model/sn-table-group";
import commonMethodMixin from "../../commonMethodMixin";
import { mapActions, mapGetters } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";
import { commonMethods } from "./model/table-methods";
export default {
    name: "equipconfig_grid",
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            commonMethods,
            infoPage: "equipconfig_form",
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            keyword: "", // 关键字
            itemID: "117",
        };
    },
    computed: {
        ...mapGetters({
            tableData: TYPES.tableData,
            tableTotal: TYPES.tableTotal,
        }),
    },
    watch: {
        tableData() {},
        tableTotal() {},
    },
    mounted() {
        this.getEquipList();
        this.getFzrList();
    },
    methods: {
        ...mapActions([
            "getSelectData",
            "GetAllEquipmentList", //设备类型列表获取
            "GetGathererList", // 获取负责人列表
            "UpdateTaskState", // 修改设备状态
        ]),
        async getTableData() {
            // 处理筛选，支持多项同时筛选
            let searchList = [];
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        let jsonObj = {
                            FieldName: item.type,
                            FieldValue: item.value,
                            OperatorChar: "=",
                        };
                        searchList.push(jsonObj);
                    }
                });
            }
            if (this.dateSearchData && this.dateSearchData.length > 0) {
                this.dateSearchData.forEach((item) => {
                    if (!item.value) return;
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "startTime") {
                            let jsonObj = {
                                FieldName: "AZSJ",
                                FieldValue: item.value,
                                OperatorChar: ">",
                            };
                            searchList.push(jsonObj);
                        } else if (item.type === "endTime") {
                            let jsonObj = {
                                FieldName: "AZSJ",
                                FieldValue: item.value,
                                OperatorChar: "<",
                            };
                            searchList.push(jsonObj);
                        }
                    }
                });
            }

            let jsonStr = JSON.stringify(searchList);
            this.tableGroupAttributes.loading = true;
            let res = await this.getSelectData({
                _cols: [], //字段集
                _keyword: this.keyword, //查询关键字
                _serarchCols: JSON.stringify([
                    "LXMC",
                    "JCDXMC",
                    "JCWZMC",
                    "SBMC",
                    "AZSJ",
                    "CJZQ",
                    "CJZQDW",
                    "GRABCYCLE",
                    "GRABCYCLEUNIT",
                    "REALNAME",
                    "ISACTIVE",
                ]), //关键字字段集合
                _conditionList: jsonStr, //过滤条件集合
                _dicOrderby: {},
                _pageSize: this.tableGroupAttributes.snPage.tiaoshu, //每页最大记录数
                _pageIndex: this.tableGroupAttributes.snPage.currentPage, //页索引
                _returnSum: true, //是否返回记录总数
                _needVersion: false, //是否分版本查询
                _searchFormal: false, //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: this.itemID, //业务编号
            });
            res.data.map((item) => {
                item.AZSJ = item.AZSJ.split(" ")[0];
            });
            this.tableGroupAttributes.snTable.table.data = res.data || [];
            this.tableGroupAttributes.snPage.count = Number(res.sum) || 0;
            this.tableGroupAttributes.loading = false;
            this.tableGroupAttributes.snTable.columns.columnsData[
                this.tableGroupAttributes.snTable.columns.columnsData.length - 1
            ] = {
                prop: "ISACTIVE",
                label: "是否启动",
                transList: {
                    0: "未启动",
                    1: "启动",
                },
                colorList: {
                    0: "#ff9066",
                    1: "#28ccd9",
                },
                operateType: "columnText",
            };
        },
        //设备类型列表获取
        async getEquipList() {
            this.equipData = [];
            let result = await this.GetAllEquipmentList();
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[0].list = result;
            }
        },
        //负责人列表获取
        async getFzrList() {
            this.tableGroupAttributes.snSearch.selectData[1].list = [];
            let result = await this.GetGathererList();
            if (result && result.length > 0) {
                this.tableGroupAttributes.snSearch.selectData[1].list = result;
            }
        },
        columnTextClick(val) {
            if (val && val.length > 0 && val[0].PZID) {
                if (val[0].ISACTIVE == "1") {
                    this.setIsactiveChanged(val[0].PZID, "0");
                } else {
                    this.setIsactiveChanged(val[0].PZID, "1");
                }
            }
        },
        isActiveChangeClick(val, state) {
            if (val && val.length > 0 && val[0].PZID) {
                if (val[0].ISACTIVE == "1" && state === "0") {
                    this.setIsactiveChanged(val[0].PZID, "0");
                } else if (val[0].ISACTIVE == "0" && state === "1") {
                    this.setIsactiveChanged(val[0].PZID, "1");
                }
            }
        },
        //启动/暂停
        async setIsactiveChanged(configid, state) {
            let res = await this.UpdateTaskState({
                configid: configid,
                state: state,
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "修改成功",
                });
                this.getTableData();
            } else {
                this.$message({
                    type: "warning",
                    message: "修改失败",
                });
            }
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {},
    },
};
</script>
<style lang="scss" scoped></style>
