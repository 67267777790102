const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "监测对象",
                prop: "JCDXMC"
            }, {
                label: "监测位置",
                prop: "JCWZMC"
            }, {
                label: "设备名称",
                prop: "SBMC",
                width: "300"
            }, {
                label: "设备类型",
                prop: "LXMC",
                width: "160"
            }, {
                label: "安装时间",
                prop: "AZSJ",
            }, {
                label: "采集周期",
                prop: "CJZQ"
            }, {
                label: "采集周期单位",
                prop: "CJZQDW_DESC"
            }, {
                label: "抓取周期",
                prop: "GRABCYCLE"
            }, {
                label: "抓取周期单位",
                prop: "GRABCYCLEUNITNAME"
            }, {
                label: "负责人",
                prop: "REALNAME"
            }, {
                label: "是否启动",
                prop: "ISACTIVE"
            }],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnEdit',
                    title: '编辑',
                    icon: 'el-icon-edit',
                    color: 'green'
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnStart',
                    title: '启动',
                    icon: 'el-icon-video-play',
                    color: 'rgb(40, 204, 217)', //'rgb(32, 160, 255)',
                    showMethod: 'showStartBtn',
                }, {
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnStop',
                    title: '停止',
                    icon: 'el-icon-video-pause',
                    color: 'rgb(255, 144, 102)', //'rgb(32, 160, 255)',
                    showMethod: 'showStopBtn',
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        selectData: [{
            label: '设备类型',
            clearable: true,
            placeholder: "请选择设备类型",
            list: [],
            optionLabel: 'MC',
            optionValue: 'ID',
            value: 'SBLX',
            operateType: 'search',
            isShow: true
        }, {
            label: '负责人',
            clearable: true,
            placeholder: "请选择负责人",
            list: [],
            optionLabel: 'Realname',
            optionValue: 'Id',
            value: 'managerid',
            operateType: 'search',
            isShow: true
        }, {
            label: '状态',
            clearable: true,
            placeholder: "请选择状态",
            list: [{
                ID: '0',
                MC: '未启动'
            }, {
                ID: '1',
                MC: '启动'
            }],
            optionLabel: 'MC',
            optionValue: 'ID',
            value: 'ISACTIVE',
            operateType: 'search',
            isShow: true
        }],
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };