const commonMethods = {
    // 启动
    showStartBtn(row) {
        if(Number(row.ISACTIVE)===1){
            return false;
        }else{
            return true;
        }
    },
    // 停止
    showStopBtn(row){
        if(Number(row.ISACTIVE)===1){
            return true;
        }else{
            return false;
        }
    }
};
export { commonMethods };